jQuery(function($){


	_odekake($);
  _toggleContent($);

});

let _odekake = ($) => {
  let _odekakeTarget = '.odekakeplus .carousel.splide';
  let _odekakeOptions = {
    type: 'loop',
    mediaQuery: 'min',
    pagination: true,
    arrows: false,
    autoHeight: true,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false, 
    interval: number = 5000,
    speed: number = 3000,
    
  }
  let _odekakeSplide = new Splide(_odekakeTarget, _odekakeOptions);
  _odekakeSplide.mount();
}


let _toggleContent = ($) => {
	$(document).on('click', '.js-toggle-btn', (e) => {
		if ( $(e.currentTarget).hasClass('open') ) {
      $(e.currentTarget).removeClass('open').next('.js-toggle-content').removeClass('open');
		} else {
      $(e.currentTarget).addClass('open').next('.js-toggle-content').addClass('open');
		}
	});
}
